import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import CSSRenderer from './CSSRenderer.js'
import World from './World/World.js'
import CannonWorld from './World/CannonWorld.js'
import Resources from './Utils/Resources.js'
import sources from './sources.js'
import EventEmitter from './Utils/EventEmitter.js'
import Mouse from './Mouse.js'
// import gsap from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Meshes from './Meshes.js'

let instance = null

export default class Experience extends EventEmitter {
  constructor (_canvas, _cssRendererWrapper) {
    super()

    // Singleton
    if (instance) {
      return instance
    }
    instance = this

    // Global access
    globalThis.experience = this

    // Options
    this.canvas = _canvas
    this.cssRendererWrapper = _cssRendererWrapper

    // Static settings
    this.objectsDistance = 40

    // Setup
    this.debug = new Debug()
    this.sizes = new Sizes()
    this.time = new Time()
    this.scene = new THREE.Scene()
    this.resources = new Resources(sources)
    this.cannonWorld = new CannonWorld()
    this.camera = new Camera()
    this.cssRenderer = new CSSRenderer()
    this.meshes = new Meshes()
    this.world = new World()
    this.renderer = new Renderer()
    this.mouse = new Mouse()
    // this.scroll = this.smoothScroll({ strContainer: '#scroll-container' })

    this.updatesList = [
      () => this.mouse.update(),
      () => this.camera.update(),
      () => this.world.update(),
      () => this.cannonWorld.update(),

      () => this.renderer.update(),
      () => this.cssRenderer.update()
    ]

    if (this.debug.active) {
      this.updatesList.push(() => this.debug.stats.update())
    }

    // Mandatory Listeners
    window.addEventListener('click', () => this.trigger('click'))

    window.addEventListener('scroll', () => this.trigger('scroll'))

    this.sizes.on('resize', () => this.resize())

    this.time.on('tick', () => {
      for (const update of this.updatesList) {
        update()
      }
    })
  }

  resize () {
    this.camera.resize()
    this.renderer.resize()
    this.cssRenderer.resize()
  }

  destroy () {
    console.log('here')
    this.sizes.off('resize')
    this.time.off('tick')

    // Traverse the whole scene
    this.scene.traverse((child) => {
      // Test if it's a mesh
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose()

        // Loop through the material properties
        for (const key in child.material) {
          const value = child.material[key]

          // Test if there is a dispose function
          if (value && typeof value.dispose === 'function') {
            value.dispose()
          }
        }
      }
    })

    this.camera.controls.dispose()
    this.renderer.instance.dispose()
    this.cssRenderer.instance.dispose()

    if (this.debug.active)
      this.debug.ui.destroy()
  }

  // // If enabled : remove other "this.trigger('scroll')"
  // smoothScroll ({ strContainer, smoothness = 1 }) {
  //     gsap.registerPlugin(ScrollTrigger)
  //     const [container] = gsap.utils.toArray(strContainer) as [HTMLElement]
  //     const getProp = gsap.getProperty(container)
  //     const setProp = gsap.quickSetter(container, 'y', 'px')
  //     const setScroll = ScrollTrigger.getScrollFunc(window)
  //     const removeScroll = () => container.style.overflow = 'visible'
  //     const killScrub = trigger => {
  //         let scrub = trigger.getTween
  //             ? trigger.getTween()
  //             : gsap.getTweensOf(trigger.animation)[0]

  //         scrub && scrub.kill()
  //         trigger.animation.progress(trigger.progress)
  //     }

  //     let height: number
  //     let isProxyScrolling: boolean

  //     function refreshHeight () {
  //         height = container.clientHeight
  //         container.style.overflow = 'visible'
  //         document.body.style.height = `${height}px`
  //         return height - document.documentElement.clientHeight
  //     }

  //     ScrollTrigger.addEventListener('refresh', () => {
  //         removeScroll()
  //         requestAnimationFrame(removeScroll)
  //     })

  //     ScrollTrigger.defaults({ scroller: container })

  //     ScrollTrigger.scrollerProxy(container, {
  //         scrollTop (value) {
  //             if (arguments.length) {
  //                 isProxyScrolling = true
  //                 setProp(-value)
  //                 setScroll(value)
  //                 return
  //             }
  //             return -getProp('y')
  //         },
  //         scrollHeight: () => document.body.scrollHeight,
  //         getBoundingClientRect () {
  //             return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
  //         }
  //     })

  //     return ScrollTrigger.create({
  //         animation: gsap.fromTo(container, { y: 0 }, {
  //             y: () => document.documentElement.clientHeight - height,
  //             ease: 'none',
  //             onUpdate: ScrollTrigger.update
  //         }),
  //         scroller: window,
  //         invalidateOnRefresh: true,
  //         start: 0,
  //         end: refreshHeight,
  //         refreshPriority: -999,
  //         scrub: smoothness,
  //         onUpdate: self => {
  //             this.trigger('scroll')
  //             if (isProxyScrolling) {
  //                 killScrub(self)
  //                 isProxyScrolling = false
  //             }
  //         },
  //         onRefresh: killScrub
  //     })
  // }
}
