import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Debug from './Utils/Debug.js'
import gsap from 'gsap'

export default class Camera {
    constructor () {
        this.experience = new Experience()
        this.debug = this.experience.debug
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.time = this.experience.time
        this.cannonWorld = this.experience.cannonWorld

        this.scrollY = window.scrollY
        this.currentSection = 0
        this.maxCameraDescent = this.sizes.perspectiveCameraHeightPercentage({
            section: 3, // Corresponds to the last section (Ground).
            coef: -20 // Max down % of innerSection (Ground).
        })

        this.experience.on('scroll', () => {
            gsap.to(this, {
                scrollY: window.scrollY,
                duration: .5,
                // ease: 'none'
            })

            const newSection = Math.round(this.scrollY / this.sizes.height)

            if (newSection !== this.currentSection) {
                this.currentSection = newSection
            }
        })

        this.cameraGroup = new THREE.Group()
        this.scene.add(this.cameraGroup)

        this.setInstance()

        this.notLastCts = document.querySelectorAll('.callToScroll:not(.refreshExperience)')
    }

    setInstance () {
        const cameras = {
            setOrbitControls: () => {
                this.scene.remove(this.cameraGroup)
                this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, .1, 200)
                this.controls = new OrbitControls(this.instance, this.canvas)
                this.controls.reset()
                this.controls.enableDamping = true
                document.getElementsByTagName('main')[0].style.display = 'none'

                //? Orbit where scroll was paused
                // const savedPosY = this.sizes.perspectiveCameraHeightPercentage({ section: this.currentSection })
                // this.instance.position.set(35, savedPosY || 35, 35)
                // this.controls.target.set( 0, savedPosY || 0, 0 )

                //? Custom column section debug
                const savedPosY = this.sizes.perspectiveCameraHeightPercentage({ section: 3 })
                this.instance.position.set(40, savedPosY + 20, 70)
                this.controls.target.set(0, savedPosY || 0, 0)

                //? Contact section debug
                // const savedPosY = this.sizes.perspectiveCameraHeightPercentage({ section: 3 })
                // this.instance.position.set(40, savedPosY + 60, 250)
                // this.controls.target.set(0, savedPosY || 0, 100)

                this.instance.updateProjectionMatrix()

                this.update = () => this.controls.update()
            },
            setPerspectiveCamera: () => {
                this.scene.remove(this.cameraGroup)
                this.scene.add(this.cameraGroup)

                this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, .1, 100)
                this.cameraGroup.add(this.instance)


                this.controls = { enabled: false }
                document.getElementsByTagName('main')[0].style.display = null
                this.instance.position.z = 50
                this.instance.updateProjectionMatrix()

                this.update = () => {
                    const nextPos = -this.scrollY / this.sizes.height * this.experience.objectsDistance

                    if (nextPos > this.maxCameraDescent) {
                        if (this.instance.position.z < 175) {
                            this.instance.position.y = nextPos
                        }

                        if (this.instance.position.z > 50) {
                            gsap.to(this.notLastCts, { autoAlpha: 1 })
                            this.instance.position.z -= 1
                        }
                    } else if (this.instance.position.z < 175) {
                        gsap.to(this.notLastCts, { autoAlpha: 0 })
                        this.instance.position.z += 1
                    }

                    if (this.experience.mouse) {
                        const parallaxX = -this.experience.mouse.pointer.x
                        const parallaxY = -this.experience.mouse.pointer.y

                        this.cameraGroup.position.x += (parallaxX - this.cameraGroup.position.x) * 5 * (this.time.delta / 1000)
                        this.cameraGroup.position.y += (parallaxY - this.cameraGroup.position.y) * 5 * (this.time.delta / 1000)
                    }
                }
            }
        }

        if (this.debug.active) {
            this.debugFolderCameras = this.debug.ui.addFolder('cameras')

            this.debugFolderCameras
                .add(cameras, 'setOrbitControls')
                .name('noClip')
            this.debugFolderCameras
                .add(cameras, 'setPerspectiveCamera')
                .name('perspective')

            this.debugFolderCameras.controllers.at(-1).$button
                .addEventListener('click', () => scrollTo(0, 0))
        }

        // cameras.setOrbitControls()
        cameras.setPerspectiveCamera()
    }

    resize () {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }
}
