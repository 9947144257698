import * as THREE from 'three'

export default function radiusPlaneGeometry(overrides: { width: number; height: number; depth: number }) {
  const defaults = {
    width: 16,
    height: 9,
    radius: 1,
    depth: 3
  }
  const { width, height, radius, depth } = Object.assign(defaults, overrides)

  const angleStep = Math.PI / 2

  const shape = new THREE.Shape()

  shape.absarc(width, height, radius, angleStep * 0, angleStep * 1, false)
  shape.absarc(-width, height, radius, angleStep * 1, angleStep * 2, false)
  shape.absarc(-width, -height, radius, angleStep * 2, angleStep * 3, false)
  shape.absarc(width, -height, radius, angleStep * 3, angleStep * 4, false)

  const geometry = new THREE.ExtrudeGeometry(shape, {
    depth,
    bevelEnabled: false
  })

  return geometry
}
