import EventEmitter from './EventEmitter.js'

export default class Time extends EventEmitter {
    constructor () {
        super()

        // Setup
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.timeStep = 1 / 60
        this.delta = this.timeStep * 1000

        window.requestAnimationFrame(() => {
            this.tick()
        })
    }

    tick () {
        const currentTime = Date.now()
        this.delta = currentTime - this.current
        this.current = currentTime
        this.elapsed = this.current - this.start

        this.trigger('tick')

        window.requestAnimationFrame(() => {
            this.tick()
        })
    }
}
