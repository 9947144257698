import * as THREE from 'three'
import Experience from '../Experience.js'
import colors from '../Utils/colors.js'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js'
import gsap from 'gsap'

export default class Environment {
    constructor () {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.time = this.experience.time
        this.sizes = this.experience.sizes

        this.home = this.experience.world.home
        this.presentation = this.experience.world.presentation
        this.skills = this.experience.world.skills
        this.projects = this.experience.world.ground.projects
        this.contact = this.experience.world.ground.contact

        this.skillsLights = []

        this.setAmbiantLight()
        this.setFog()
        this.setHomeLights()
        this.setPresentationLights()
        this.setSkillsLights()
        this.setProjectsLights()
        this.setContactLights()
        
        this.skills.on('skillChanged', () => this.updateSkillsLights())
    }

    setFog () {
        this.fog = new THREE.Fog(colors.black, 0, 125)
        this.scene.fog = this.fog

        this.experience.renderer.instance.setClearColor(this.fog.color)
    }

    setAmbiantLight () {
        const ambientLight = new THREE.AmbientLight(0xffffff, .1)
        this.scene.add(ambientLight)
    }

    setHomeLights () {
        const [cube, pyramid] = this.home.polygonsGroup.children

        // Cube inner light
        this.cubeInnerLight = new THREE.PointLight(colors.pink, 18, 5)
        this.cubeInnerLight.relativePos = cube.position
        this.cubeInnerLight.position.copy(cube.position)
        this.home.polygonsGroup.add(this.cubeInnerLight)

        // Pyramid inner light
        this.pyramidInnerLight = new THREE.PointLight(colors.yellow, 10, 5)
        this.pyramidInnerLight.relativePos = pyramid.position
        this.pyramidInnerLight.position.copy(pyramid.position)
        this.home.polygonsGroup.add(this.pyramidInnerLight)

        // Title light
        const pointLight = new THREE.PointLight(colors.green, 5, 30)
        pointLight.position.set(0, 0, 10)
        this.scene.add(pointLight)
    }

    setPresentationLights () {
        const textMesh = this.presentation.presentationTitleMesh
        textMesh.geometry.computeBoundingBox()

        const textBox = new THREE.Box3()
        textBox.copy(textMesh.geometry.boundingBox)
        const { x: textWidth, y: textHeight } = textBox.getSize(new THREE.Vector3())

        const textColor = textMesh.material.color

        const textRectLight = new THREE.RectAreaLight(textColor, 10, textWidth, textHeight)

        textRectLight.position.x = 7.75
        textRectLight.position.y = 12
        textRectLight.position.z = 3

        textRectLight.rotation.x = -Math.PI / 4

        this.presentation.presentationScreen.add(textRectLight)

        // const textRectLightHelper = new RectAreaLightHelper(textRectLight)
        // textRectLight.add(textRectLightHelper)
        
        const subScreensGroup = this.presentation.subScreens

        const innerScreensBox = new THREE.Box3()
        innerScreensBox.setFromObject(subScreensGroup)
        const { x: innerScreenWidth, y: innerScreenHeight } = innerScreensBox.getSize(new THREE.Vector3())

        const innerScreenColor = colors.white

        const innerScreenRectLight = new THREE.RectAreaLight(innerScreenColor, 3, innerScreenWidth, innerScreenHeight)

        innerScreenRectLight.position.z = 10
        
        // const innerScreenRectLightHelper = new RectAreaLightHelper(innerScreenRectLight)
        // innerScreenRectLight.add(innerScreenRectLightHelper)
        
        this.presentation.presentationScreen.add(innerScreenRectLight)
    }

    setSkillsLights () {
        // Circle central light
        const pointLight = new THREE.PointLight(colors.white, 1, 30)
        this.skills.skillsGroup.add(pointLight)
        // const pointLightHelper = new THREE.PointLightHelper(pointLight)
        // this.scene.add(pointLightHelper)

        // Skills lights
        this.skills.skillsCircle.traverse(child => {
            if (child instanceof THREE.Group && child.name) {
                const box = new THREE.Box3().setFromObject(child)
                // const helper = new THREE.Box3Helper(box, this.skills.getChildColor(child.name))
                // this.skills.skillsGroup.add(helper)

                const { x: width, y: height } = box.getSize(new THREE.Vector3())
                const { x: centerX, y: centerY } = box.getCenter(new THREE.Vector3())

                const rectLight = new THREE.RectAreaLight(
                    this.skills.getChildColor(child.name),
                    10,
                    width,
                    height
                )

                rectLight.position.set(centerX, centerY, 10)

                // const rectLightHelper = new RectAreaLightHelper(rectLight)
                // rectLight.add(rectLightHelper)

                this.skillsLights.push([child.name, rectLight])

                this.skills.skillsGroup.add(rectLight)
            }
        })
    }

    updateSkillsLights () {
        for (const [name, light] of this.skillsLights) {
            let intensity = 0

            if (name === this.skills.currentSkill) {
                switch (name) {
                    case 'cao':
                        intensity = 40
                        break
                    case 'cicd':
                        intensity = 80
                        break
                    case 'gestionProjets':
                        intensity = 5
                        break
                    default:
                        intensity = 10
                        break
                }
            }

            gsap.to(light, { intensity })
        }
    }

    setProjectsLights () {
        for (const [screenGroup] of this.projects.projectsScreens) {
            const innerScreenMesh = screenGroup.children[1]

            const innerScreenBox = new THREE.Box3().setFromObject(innerScreenMesh)

            const { x: innerScreenWidth, y: innerScreenHeight } = innerScreenBox.getSize(new THREE.Vector3())
            const { x: centerX, y: centerY } = innerScreenBox.getCenter(new THREE.Vector3())

            const innerScreenRectLight = new THREE.RectAreaLight(
                colors.white,
                20,
                innerScreenWidth,
                innerScreenHeight
            )

            innerScreenRectLight.position.set(centerX, centerY, 3.5)

            innerScreenRectLight.rotation.x = Math.PI

            // const innerScreenRectLightHelper = new RectAreaLightHelper(innerScreenRectLight)
            // innerScreenRectLight.add(innerScreenRectLightHelper)

            innerScreenMesh.parent.add(innerScreenRectLight)
        }
    }

    setContactLights () {
        const baseColor = new THREE.Color(colors.white)
        const baseIntensity = 2.5
        this.globalContactLight = new THREE.PointLight(baseColor, baseIntensity, 100)

        this.globalContactLight.baseColor = baseColor
        this.globalContactLight.baseIntensity = baseIntensity

        this.contact.contactGroup.add(this.globalContactLight)
        // const globalContactLightHelper = new THREE.PointLightHelper(this.globalContactLight)
        // this.scene.add(globalContactLightHelper)

        this.mailBoxLight = new THREE.PointLight(colors.orange, 6.5, 15)
        this.contact.contactGroup.add(this.mailBoxLight)

        this.mailBoxLight.position.copy(this.contact.mailBox.position)
        this.mailBoxLight.position.y = -7

        // const mailBoxLightHelper = new THREE.PointLightHelper(this.mailBoxLight)
        // this.scene.add(mailBoxLightHelper)
    }

    update () {
        // Home lights update
        this.cubeInnerLight.position.copy(this.cubeInnerLight.relativePos)
        this.pyramidInnerLight.position.copy(this.pyramidInnerLight.relativePos)
        
        this.mailBoxLight.intensity = Math.abs(Math.cos(this.time.elapsed / 1000) + Math.sin(this.time.elapsed / 750))
    }
}
