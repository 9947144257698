import * as THREE from 'three'
import Experience from '../../Experience.js'
import * as CANNON from 'cannon-es'
import colors from '../../Utils/colors.js'
import CannonUtils from '../../Utils/CannonUtils'
import radiusPlaneGeometry from '../../Utils/radiusPlaneGeometry'

export default class Projects {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.cannonWorld = this.experience.cannonWorld
    this.ground = this.experience.world.ground
    this.meshes = this.experience.meshes

    this.projectsScreens = []

    this.projectsVideos = [
      this.resources.items.epiconscienceWebsitePreview,
      this.resources.items.epiconscienceWebsitePreview,
      this.resources.items.epiconscienceWebsitePreview
    ]

    this.videosTextures = []

    this.setProjectsBoxes()
  }

  setProjectsBoxes () {
    for (let i = 0; i < 3; i++) {
      this.setProjectsScreens(i)
    }
  }

  setProjectsScreens (i) {
    const presentationScreen = new THREE.Group()

    const angleStep = Math.PI * .5
    const radius = 1

    const size = 12
    const spacing = 4

    // Outer Screen

    const outerScreenGeometry = radiusPlaneGeometry({
      width: size / 2,
      height: 9,
      depth: 3
    })

    const outerScreenMaterial = this.meshes.materials.standard.black

    const outerScreen = new THREE.Mesh(
      outerScreenGeometry,
      outerScreenMaterial
    )

    // Inner Screen

    function roundedPlaneGeometry (video) {
      video.play()

      const ar = video.videoWidth / video.videoHeight
      const w = size // width
      const h = size / ar	// height
      const r = 1;	// radius corner
      const s = 5;	// smoothness

      // helper const's
      const wi = w / 2 - r
      const hi = h / 2 - r
      const w2 = w / 2
      const h2 = h / 2
      const ul = r / w
      const ur = (w - r) / w
      const vl = r / h
      const vh = (h - r) / h

      let positions = [
        -wi, -h2, 0, wi, -h2, 0, wi, h2, 0,
        -wi, -h2, 0, wi, h2, 0, -wi, h2, 0,
        -w2, -hi, 0, -wi, -hi, 0, -wi, hi, 0,
        -w2, -hi, 0, -wi, hi, 0, -w2, hi, 0,
        wi, -hi, 0, w2, -hi, 0, w2, hi, 0,
        wi, -hi, 0, w2, hi, 0, wi, hi, 0
      ]

      let uvs = [
        ul, 0, ur, 0, ur, 1,
        ul, 0, ur, 1, ul, 1,
        0, vl, ul, vl, ul, vh,
        0, vl, ul, vh, 0, vh,
        ur, vl, 1, vl, 1, vh,
        ur, vl, 1, vh, ur, vh
      ];

      let phia = 0;
      let phib, xc, yc, uc, vc;

      for (let i = 0; i < s * 4; i++) {
        phib = Math.PI * 2 * (i + 1) / (4 * s);

        xc = i < s || i >= 3 * s ? wi : - wi;
        yc = i < 2 * s ? hi : -hi;

        positions.push(xc, yc, 0, xc + r * Math.cos(phia), yc + r * Math.sin(phia), 0, xc + r * Math.cos(phib), yc + r * Math.sin(phib), 0);

        uc = xc = i < s || i >= 3 * s ? ur : ul;
        vc = i < 2 * s ? vh : vl;

        uvs.push(uc, vc, uc + ul * Math.cos(phia), vc + vl * Math.sin(phia), uc + ul * Math.cos(phib), vc + vl * Math.sin(phib));

        phia = phib
      }

      const videoTexture = new THREE.VideoTexture(video)
      videoTexture.minFilter = THREE.LinearFilter
      videoTexture.magFilter = THREE.LinearFilter

      const material = new THREE.MeshBasicMaterial({ map: videoTexture })

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3));
      geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));

      return new THREE.Mesh(geometry, material);
    }

    const innerScreen = roundedPlaneGeometry(this.projectsVideos[i])
    innerScreen.name = `project-${i}`
    innerScreen.position.z = 3.25

    presentationScreen.add(outerScreen, innerScreen)

    presentationScreen.position.y = this.sizes.perspectiveCameraHeightPercentage({
      section: this.ground.sectionHeight,
      coef: -17
    })

    switch (i) {
      case 0:
        presentationScreen.position.x = -size - spacing
        presentationScreen.rotation.y = Math.PI / 6
        presentationScreen.position.z = -7
        break
      case 1:
        presentationScreen.position.z = -size * .8
        break
      case 2:
        presentationScreen.position.x = size + spacing
        presentationScreen.rotation.y = -Math.PI / 6
        presentationScreen.position.z = -7
        break
    }

    // CannonES box
    const screenBody = new CANNON.Body({
      mass: 0,
      shape: CannonUtils.CreateConvexPolyhedron(outerScreenGeometry),
      material: this.cannonWorld.physicsMaterial
    })

    screenBody.position.copy(presentationScreen.position)
    screenBody.quaternion.copy(presentationScreen.quaternion)


    this.cannonWorld.world.addBody(screenBody)

    this.projectsScreens.push([presentationScreen, screenBody])
    this.scene.add(presentationScreen)
  }
}
