import * as THREE from 'three'
import Experience from './Experience.js'
import openLink from './Utils/openLink'

export default class Mouse {
  constructor () {
    this.experience = new Experience()
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera

    this.pointer = new THREE.Vector2()

    this.raycaster = new THREE.Raycaster()
    this.intersectables = {
      cubes: [],
      socialBoxes: []
    }

    this.setMouseEvents()
  }

  setMouseEvents () {
    window.addEventListener('pointermove', e => {
      this.pointer.x = (e.clientX / this.sizes.width) * 2 - 1
      this.pointer.y = - (e.clientY / this.sizes.height) * 2 + 1
    })

    window.addEventListener('click', () => {
      this.raycaster.setFromCamera(this.pointer, this.camera.instance)
      for (const intersect of this.raycaster.intersectObjects(this.intersectables.socialBoxes, false)) {
        openLink(intersect.object.url)
      }

      for (const intersect of this.raycaster.intersectObject(this.intersectables.mailBox, true)) {
        openLink('mailto:p.darrieutort@outlook.fr?subject=CONTACT_FROM_PORTFOLIO', false)
      }
    })
  }

  updateRaycaster () {
    let isClickable = false

    this.raycaster.setFromCamera(this.pointer, this.camera.instance)

    const mailBox = this.intersectables.mailBox

    if (mailBox) {
      const mailBoxIntersects = this.raycaster.intersectObject(mailBox, true)[0]

      !isClickable && (isClickable = Boolean(mailBoxIntersects))
    }

    const cubes = this.intersectables.cubes
    for (const cube of cubes) {
      cube.material.color.set(0xffffff)
    }
    const cubeIntersect = this.raycaster.intersectObjects(cubes, false)[0]
    if (cubeIntersect) {
      cubeIntersect.object.material.color.set(0x00ff00)
    }

    const socialBoxIntersect = this.raycaster.intersectObjects(this.intersectables.socialBoxes, false)[0]
    const globalContactLight = this.experience.world.environment?.globalContactLight

    if (globalContactLight) {
      if (socialBoxIntersect) {
        globalContactLight.color = socialBoxIntersect.object.color
        globalContactLight.intensity = 5
      } else {
        globalContactLight.color = globalContactLight.baseColor
        globalContactLight.intensity = globalContactLight.baseIntensity
      }

      !isClickable && (isClickable = Boolean(socialBoxIntersect))
    }

    isClickable
      ? document.body.classList.add('rayClickable')
      : document.body.classList.remove('rayClickable')
  }

  update () {
    this.updateRaycaster()
  }
}
