import EventEmitter from './EventEmitter.js'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Font } from 'three/examples/jsm/loaders/FontLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
import colors from './colors.js'

export default class Resources extends EventEmitter {
  constructor (sources) {
    super()

    this.sources = sources

    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0

    this.setLoaders()
    this.startLoading()
  }

  setLoaders () {
    this.loaders = {}
    this.loaders.gltfLoader = new GLTFLoader()
    this.setDRACOLoader()
    this.loaders.textureLoader = new THREE.TextureLoader()
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
    this.loaders.svgLoader = new SVGLoader()
  }

  setDRACOLoader () {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco/')
    this.loaders.gltfLoader.setDRACOLoader(dracoLoader)
  }

  startLoading () {
    // Load each source
    for (const source of this.sources) {
      switch (source.type) {
        case 'gltfModel':
          this.loaders.gltfLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file)
            }
          )
          break
        case 'texture':
          this.loaders.textureLoader.load(
            source.path,
            (file) => {
              if (source.useNearestFilter) {
                file.generateMipmaps = false
                file.magFilter = THREE.NearestFilter
                file.minFilter = THREE.NearestFilter
              }

              file.name = source.name

              this.sourceLoaded(source, file)
            }
          )
          break
        case 'cubeTexture':
          this.loaders.cubeTextureLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file)
            }
          )
          break
        case 'font':
          const font = new Font(source.path)
          this.sourceLoaded(source, font)
          break
        case 'svg':
          this.loaders.svgLoader.load(
            source.path,
            (file) => {
              const group = new THREE.Group()
              const ids = {}

              for (const { id, localName } of Object.values(file.xml.children)) {
                if (localName === 'g') {
                  ids[id] = new THREE.Group()
                  ids[id].name = id
                  group.add(ids[id])
                }
              }

              for (const path of file.paths) {

                const shapes = SVGLoader.createShapes(path)

                for (const shape of shapes) {
                  const geometry = new THREE.ShapeGeometry(shape)
                  geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI))

                  const material = new THREE.MeshStandardMaterial({
                    color: colors.black,
                    side: THREE.BackSide,
                    // depthWrite: false
                  })

                  const mesh = new THREE.Mesh(geometry, material)

                  ids[path.userData.node.parentElement.id].add(mesh)
                }
              }

              group.center = function () {
                new THREE.Box3()
                  .setFromObject(group)
                  .getCenter(group.position)
                  .multiplyScalar(-1)
              }

              this.sourceLoaded(source, group)
            }
          )
          break
        case 'video':
          const video = document.createElement('video')
          video.loop = true
          video.muted = true
          video.autoplay = true
          video.playsInline = true
          video.hidden = true
          video.className = 'three-texture-video'
          video.id = source.name

          const videoSource = document.createElement('source')
          videoSource.type = source.mime
          videoSource.src = source.path

          video.appendChild(videoSource)
          document.body.appendChild(video)

          this.sourceLoaded(source, video)
          break
      }
    }
  }

  sourceLoaded (source, file) {
    this.items[source.name] = file

    this.loaded++

    if (this.loaded === this.toLoad) {
      this.trigger('ready')
    }
  }
}
