import Experience from '../Experience.js'
import Environment from './Environment.js'
import * as THREE from 'three'
import * as CANNON from 'cannon-es'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import CannonDebugger from 'cannon-es-debugger'

export default class CannonWorld {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.time = this.experience.time
    this.debug = this.experience.debug

    this.world = new CANNON.World()

    this.balls = []
    this.ballMeshes = []
    this.boxes = []
    this.boxMeshes = []

    this.setCannonWorld()

    this.updatesList = [
      () => this.world.step(this.time.timeStep, this.time.delta / 1000)
    ]

    if (this.debug.active) {
      this.isDebuggerEnabled = false

      this.debug.ui.add(this, 'isDebuggerEnabled', !this.isDebuggerEnabled).name('CANNON Wireframes')
      this.cannonDebugger = new CannonDebugger(this.experience.scene, this.world, {
        onInit: (body, mesh) => mesh.visible = this.isDebuggerEnabled,
        onUpdate: (body, mesh) => mesh.visible = this.isDebuggerEnabled
      })

      this.updatesList.push(() => this.cannonDebugger.update())
    }
  }


  setCannonWorld () {
    this.#tweakCannonEngine()

    this.world.gravity.set(0, -9.81, 3)

    // Create a slippery material (friction coefficient = 0.0)
    this.physicsMaterial = new CANNON.Material('physics')
    const physics_physics = new CANNON.ContactMaterial(
      this.physicsMaterial,
      this.physicsMaterial,
      {
        friction: 1,
        restitution: .3,
      }
    )

    // We must add the contact materials to the world
    this.world.addContactMaterial(physics_physics)
  }

  #tweakCannonEngine () {
    // I don't understand a piece of this method :/
    // Tweak contact properties.
    // Contact stiffness - use to make softer/harder contacts
    this.world.defaultContactMaterial.contactEquationStiffness = 1e9
    // Stabilization time in number of timesteps
    this.world.defaultContactMaterial.contactEquationRelaxation = 4
    const solver = new CANNON.GSSolver()
    solver.iterations = 7
    solver.tolerance = .1
    this.world.solver = new CANNON.SplitSolver(solver)
  }

  update () {
    this.updatesList.forEach(update => update())
  }
}
