import * as THREE from 'three'
import Experience from '../Experience.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import colors from '../Utils/colors.js'
import radiusPlaneGeometry from '../Utils/radiusPlaneGeometry'

export default class Presentation {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.cssRenderer = this.experience.cssRenderer
    this.meshes = this.experience.meshes

    this.setPresentationScreen()
  }

  setPresentationScreen () {
    this.presentationScreen = new THREE.Group()

    //? Outer Screen /////////////////////////////////////////////////////////////

    const outerScreenGeometry = radiusPlaneGeometry()
    const outerScreenMaterial = this.meshes.materials.standard.black

    const outerScreen = new THREE.Mesh(outerScreenGeometry, outerScreenMaterial)

    //? Presentation Screen Title /////////////////////////////////////////////////

    const presentationTitle = new TextGeometry('Qui suis-je ?', {
      font: this.resources.items.marvinVisionsBold,
      size: 2.5,
      height: .2
    })

    this.presentationTitleMesh = new THREE.Mesh(
      presentationTitle,
      this.meshes.materials.standard.green
    )

    this.presentationTitleMesh.position.set(-1, 10.5, 1.5)

    //? Inner Screens ////////////////////////////////////////////////////////////

    //* One big Inner Screen
    // const innerScreenMaterial = new THREE.MeshStandardMaterial({ color: colors.grey })
    // const innerScreenGeometry = radiusPlaneGeometry({ width: 12.8, height: 7.2, depth: .25 })
    // this.innerScreen = new THREE.Mesh(innerScreenGeometry, innerScreenMaterial)
    // this.innerScreen.position.z = 3

    this.subScreens = new THREE.Group()
    this.subScreens.position.z = 3

    const [text1, text2, text3] = document.querySelectorAll('#presentation > .presentation-text')
    const subScreens = [
      {
        geometry: radiusPlaneGeometry({ width: 9.1, height: 4.8, depth: .25 }),
        backMaterial: this.meshes.materials.standard.pink,
        offset: { x: -5.9, y: 3.4 },
        text: text1
      },
      {
        geometry: radiusPlaneGeometry({ width: 4.5, height: 4.8, depth: .25 }),
        backMaterial: this.meshes.materials.standard.purple,
        offset: { x: 10.5, y: 3.4 },
        text: text2
      },
      {
        geometry: radiusPlaneGeometry({ width: 15, height: 2, depth: .25 }),
        backMaterial: this.meshes.materials.standard.green,
        offset: { x: 0, y: -6.2 },
        text: text3
      }
    ]

    const cssThreeGroup = this.cssRenderer.setGroupFromThree(this.presentationScreen)

    for (const { geometry, offset, text, backMaterial } of subScreens) {
      const subScreenGroup = new THREE.Group()

      subScreenGroup.position.x = offset.x
      subScreenGroup.position.y = offset.y

      this.subScreens.add(subScreenGroup)


      if (this.debug.active) {
        const debugFolder = this.debug.ui.addFolder(`Sub-screen ${this.subScreens.children.length + 1}`)
  
        debugFolder
          .add(subScreenGroup.position, 'x')
          .step(.1)
          .min(-15)
          .max(15)
  
        debugFolder
          .add(subScreenGroup.position, 'y')
          .step(.1)
          .min(-15)
          .max(15)
      }

      const subScreen = new THREE.Mesh(geometry, backMaterial)
      subScreenGroup.add(subScreen)

      const cssObjOverrides = {
        positionZ: this.subScreens.position.z + .5,
        scale: .032
      }

      const cssObject = this.cssRenderer.createCssObject({
        position: new THREE.Vector3(offset.x, offset.y, cssObjOverrides.positionZ),
        rotation: subScreenGroup.rotation,
        domElement: text
      })

      cssObject.scale.set(
        cssObjOverrides.scale,
        cssObjOverrides.scale,
        cssObjOverrides.positionZ / cssObjOverrides.scale
      )

      cssThreeGroup.add(cssObject)
    }

    //? Global insertion //////////////////////////////////////////////////////////

    this.presentationScreen.rotation.set(0, Math.PI / 12, -Math.PI / 30)
    this.presentationScreen.position.y = this.sizes.perspectiveCameraHeightPercentage({ section: 1 })

    this.presentationScreen.add(outerScreen, this.subScreens, this.presentationTitleMesh)
    this.scene.add(this.presentationScreen)
  }

  update () {
    this.presentationScreen.rotation.x = Math.cos(this.time.elapsed / 3000) * Math.PI / 80
    this.presentationScreen.rotation.y = Math.sin(this.time.elapsed / 3000) * Math.PI / 22
    this.presentationScreen.position.x = this.sizes.perspectiveCameraWidthPercentage(Math.sin(this.time.elapsed / 1000))
    this.presentationScreen.position.y = this.sizes.perspectiveCameraHeightPercentage({
      coef: (Math.cos(this.time.elapsed / 1500) * 5),
      section: 1
    })
  }
}
