import * as THREE from 'three'
import Experience from './Experience.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

export default class Renderer {
  constructor () {
    this.experience = new Experience()
    this.canvas = this.experience.canvas
    this.sizes = this.experience.sizes
    this.scene = this.experience.scene
    this.camera = this.experience.camera

    this.updatesList = []

    this.setInstance()
    this.setComposer()
  }

  setInstance () {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      powerPreference: 'high-performance',
    })
    this.instance.shadowMap.autoUpdate = false
    this.instance.shadowMap.enabled = false
    this.instance.shadowMap.needsUpdate = true
    // this.instance.physicallyCorrectLights = true
    // this.instance.outputEncoding = THREE.sRGBEncoding
    // this.instance.toneMapping = THREE.CineonToneMapping
    // this.instance.toneMappingExposure = 1.75
    // this.instance.shadowMap.enabled = true
    // this.instance.shadowMap.type = THREE.PCFSoftShadowMap
    this.instance.setClearColor('#211d20')
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))

    this.updatesList.push(() => this.instance.render(this.scene, this.camera.instance))
  }

  resize () {
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
  }

  update () {
    this.updatesList.forEach(update => update())
  }

  setComposer () {
    //COMPOSER
    this.composer = new EffectComposer(this.instance)
    const renderPass = new RenderPass(this.scene, this.camera.instance)
    this.composer.addPass(renderPass)

    //custom shader pass
    this.composerCounter = 0.0
    const myEffect = {
      uniforms: {
        "tDiffuse": { value: null },
        "amount": { value: this.composerCounter }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix 
            * modelViewMatrix 
            * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        uniform float amount;
        uniform sampler2D tDiffuse;
        varying vec2 vUv;
      
        float random( vec2 p )
        {
          vec2 K1 = vec2(
            23.14069263277926, // e^pi (Gelfond's constant)
            2.665144142690225 // 2^sqrt(2) (Gelfondâ€“Schneider constant)
          );
          return fract( cos( dot(p,K1) ) * 12345.6789 );
        }
      
        void main() {
      
          vec4 color = texture2D( tDiffuse, vUv );
          vec2 uvRandom = vUv;
          uvRandom.y *= random(vec2(uvRandom.y,amount));
          color.rgb += random(uvRandom)*0.15;
          gl_FragColor = vec4( color  );
        }
      `
    }

    this.customPass = new ShaderPass(myEffect)
    this.customPass.renderToScreen = true
    this.composer.addPass(this.customPass)

    this.updatesList.push(() => {
      this.composerCounter += 0.01
      this.customPass.uniforms["amount"].value = this.composerCounter
      this.composer.render()
    })
  }
}
