import * as THREE from 'three'
import Experience from '../Experience.js'
import * as CANNON from 'cannon-es'
import colors from '../Utils/colors.js'
import gsap from 'gsap'
import EventEmitter from '../Utils/EventEmitter.js'

export default class Skills extends EventEmitter {
  constructor () {
    super()

    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.cannonWorld = this.experience.cannonWorld
    this.meshes = this.experience.meshes

    this.sectionHeight = 2
    this.loopingTime = 0
    this.skillsGroup = new THREE.Group()
    this.cubes = []

    this.logos = {
      frontend: [
        this.resources.items.logoJS,
        this.resources.items.logoTS,
        this.resources.items.logoGsap,
        this.resources.items.logoThree
      ],
      backend: [
        this.resources.items.logoGraphQL,
        this.resources.items.logoNodeJS,
        this.resources.items.logoStrapi
      ],
      cybersecurite: [
        this.resources.items.logoOWASP,
        this.resources.items.logoBurp
      ],
      cao: [

      ],
      gestionProjets: [
        this.resources.items.logoProject365,
        this.resources.items.logoGithub
      ],
      cicd: [
        this.resources.items.logoLighthouse,
        this.resources.items.logoDocker
      ]
    }

    this.setLogosMaterials()
    this.setSkillsCircle()

    this.on('skillChanged', () => {
      this.paintCircle(this.currentSkill)
      this.setBox(this.currentSkill)
    })
  }

  setLogosMaterials () {
    const textures = Object.values(this.logos).flat()

    for (const texture of textures) {
      this.meshes.addMaterial(texture.name, 'standard', { map: texture })
    }
  }

  setSkillsCircle () {
    this.skillsCircle = this.resources.items.skillsCircle

    for (const i in this.skillsCircle.children) {
      const child = this.skillsCircle.children[i]
      this[child.name] = child
    }

    this.skillsCircle.scale.multiplyScalar(0.045)
    this.skillsCircle.center()
    this.skillsCircle.updateMatrixWorld()

    this.skillsGroup.add(this.skillsCircle)

    this.skillsGroup.position.y = this.sizes.perspectiveCameraHeightPercentage({
      section: this.sectionHeight
    })
    this.scene.add(this.skillsGroup)
  }

  paintCircle (name) {
    this.skillsCircle.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.material.color.set(this.getChildColor(child.parent.name === name ? name : 'default'))
      }
    })
  }

  getChildColor (name) {
    switch (name) {
      case 'frontend':
        return colors.yellow
      case 'backend':
        return colors.pink
      case 'cybersecurite':
        return colors.white
      case 'cao':
        return colors.orange
      case 'gestionProjets':
        return colors.green
      case 'cicd':
        return colors.purple
      default:
        return colors.grey
    }
  }

  getChildMaterial (name) {
    const randomInLogoArrayCurrentThemeTexture = this.logos[name][Math.random() * this.logos[name].length | 0]?.name
    return this.meshes.materials.standard[randomInLogoArrayCurrentThemeTexture]
  }

  setBox (name) {
    const cubeSize = Math.random() * 4 + 2.75 // Random * max + min

    // ThreeJS box
    const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize)
    const material = this.getChildMaterial(name)
    const cube = new THREE.Mesh(geometry, material)
    cube.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI)
    cube.scale.set(0, 0, 0)
    cube.position.z = -10
    cube.position.y = this.skillsGroup.position.y
    this.scene.add(cube)

    // CannonES box
    const cubeBody = new CANNON.Body({
      mass: 50 * cubeSize,
      shape: new CANNON.Box(new CANNON.Vec3(cubeSize / 2, cubeSize / 2, cubeSize / 2)),
      material: this.cannonWorld.physicsMaterial
    })
    cubeBody.position.copy(cube.position)
    cubeBody.quaternion.copy(cube.quaternion)

    this.cannonWorld.world.addBody(cubeBody)

    // [tjs, ces] addition
    this.cubes.push([cube, cubeBody])

    // Raycasting addition
    this.experience.mouse.intersectables.cubes.push(cube)
  }

  update () {
    const timeModuloSize = parseInt(this.time.elapsed / 1000) % this.skillsCircle.children.length

    if (this.loopingTime !== timeModuloSize) {
      this.loopingTime = timeModuloSize

      this.currentSkill = this.skillsCircle.children[timeModuloSize].name

      this.trigger('skillChanged')
    }

    // Kill cubes overflow
    if (this.cubes.length > 29) {
      const [tjs, ces] = this.cubes.shift()

      // tjs removal
      const tjsObj = this.scene.getObjectByProperty('uuid', tjs.uuid)
      tjsObj.geometry.dispose()
      tjsObj.material.dispose()
      this.scene.remove(tjsObj)

      // ces removal
      this.cannonWorld.world.removeBody(ces)

      // Raycasting removal
      this.experience.mouse.intersectables.cubes.shift()
    }

    for (const [tjs, ces] of this.cubes) {
      if (tjs.scale.x < 1) {
        tjs.scale.set(tjs.scale.x + .01, tjs.scale.y + .01, tjs.scale.z + .01)
      }

      tjs.position.copy(ces.position)
      tjs.quaternion.copy(ces.quaternion)
    }
  }
}
