import * as THREE from 'three'
import Experience from '../../Experience.js'
import * as CANNON from 'cannon-es'
import colors from '../../Utils/colors.js'
import Projects from './Projects.js'
import Contact from './Contact.js'

export default class Ground {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.cannonWorld = this.experience.cannonWorld
    this.meshes = this.experience.meshes

    this.sectionHeight = 3
    this.groundPosY = this.sizes.perspectiveCameraHeightPercentage({ section: this.sectionHeight })

    this.updatesList = []

    this.setGroundMaterial()
    this.setProjectsSceneBorders()
    this.setProjectsGround()

    this.resources.on('ready', () => this.initGroundScenes())
  }

  initGroundScenes () {
    this.projects = new Projects()
    this.contact = new Contact()

    // this.updatesList.push(() => this.projects.update())
    // this.updatesList.push(() => this.contact.update())
  }

  setGroundMaterial () {
    this.meshes.addMaterial('customGrey', 'standard', {
      color: colors.grey,
      side: THREE.BackSide,
      metalness: .5,
      roughness: .5
    })
  }

  getGroundCircle () {
    const geometry = new THREE.CircleGeometry(50, 50)
    const material = this.meshes.materials.standard.customGrey

    const plane = new THREE.Mesh(geometry, material)
    plane.rotation.x = Math.PI / 2

    return plane
  }

  setProjectsGround () {
    // ThreeJS ground
    const plane = this.getGroundCircle()
    plane.position.y = this.sizes.perspectiveCameraHeightPercentage({
      section: this.sectionHeight,
      coef: -60
    })
    this.scene.add(plane)

    // CannonES ground
    const groundBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: this.cannonWorld.physicsMaterial,
    })

    groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0)
    groundBody.position.copy(plane.position)

    this.cannonWorld.world.addBody(groundBody)
  }

  setProjectsSceneBorders () {
    // Plane -x, left
    const planeShapeXmin = new CANNON.Plane()
    const planeXmin = new CANNON.Body({ mass: 0 })
    planeXmin.addShape(planeShapeXmin)
    planeXmin.quaternion.setFromEuler(0, Math.PI / 2, 0)
    planeXmin.position.set(-25, this.groundPosY, 0)
    this.cannonWorld.world.addBody(planeXmin)

    // Plane +x, right
    const planeShapeXmax = new CANNON.Plane()
    const planeXmax = new CANNON.Body({ mass: 0 })
    planeXmax.addShape(planeShapeXmax)
    planeXmax.quaternion.setFromEuler(0, -Math.PI / 2, 0)
    planeXmax.position.set(25, this.groundPosY, 0)
    this.cannonWorld.world.addBody(planeXmax)

    // Plane -z, back
    const planeShapeZmin = new CANNON.Plane()
    const planeZmin = new CANNON.Body({ mass: 0 })
    planeZmin.addShape(planeShapeZmin)
    planeZmin.quaternion.setFromEuler(0, 0, 0)
    planeZmin.position.set(0, this.groundPosY, -30)
    this.cannonWorld.world.addBody(planeZmin)

    // Plane +z, front
    const planeShapeZmax = new CANNON.Plane()
    const planeZmax = new CANNON.Body({ mass: 0 })
    planeZmax.addShape(planeShapeZmax)
    planeZmax.quaternion.setFromEuler(0, Math.PI, 0)
    planeZmax.position.set(0, this.groundPosY, 40)
    this.cannonWorld.world.addBody(planeZmax)
  }

  update () {
    this.updatesList.forEach(update => update())
  }
}
