import * as THREE from 'three'
import Experience from './Experience.js'
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js'

export default class CSSRenderer {
  constructor () {
    this.experience = new Experience()
    this.cssRendererWrapper = this.experience.cssRendererWrapper
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera

    this.setInstance()
    this.setCssScene()
  }

  setInstance () {
    this.instance = new CSS3DRenderer()
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.cssRendererWrapper.appendChild(this.instance.domElement)
  }

  setCssScene () {
    this.cssScene = new THREE.Scene()
  }

  createCssObject ({
    position = new THREE.Vector3(),
    rotation = new THREE.Euler(),
    domElement
  }) {
    const cssObject = new CSS3DObject(domElement)

    cssObject.position.copy(position)
    cssObject.rotation.copy(rotation)

    return cssObject
  }

  setGroupFromThree (threeInheritedObject) {
    const group = new THREE.Group()

    group.name = threeInheritedObject.name
    group.threeGroup = threeInheritedObject

    this.cssScene.add(group)

    return group
  }

  resize () {
    this.instance.setSize(this.sizes.width, this.sizes.height)
  }

  update () {
    this.cssScene.traverse(object => {
      if (object.threeGroup) {
        object.position.copy(object.threeGroup.position)
        object.rotation.copy(object.threeGroup.rotation)
      }
    })

    this.instance.render(this.cssScene, this.camera.instance)
  }
}
