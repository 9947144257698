import * as THREE from 'three'
import Experience from '../Experience.js'
import Environment from './Environment.js'
import Home from './Home.js'
import Presentation from './Presentation.js'
import Skills from './Skills.js'
import Ground from './Ground/Ground.js'

export default class World {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.cssRenderer = this.experience.cssRenderer
    this.sizes = this.experience.sizes
    this.meshes = this.experience.meshes

    this.updatesList = []

    this.home = new Home()
    this.ground = new Ground()
    this.resources.on('ready', () => this.initWorldScenes())

    this.particlesManager()

    this.setCallsToScroll()
  }

  setCallsToScroll () {
    const paramsArray = [
      { section: 0, coef: -60 },
      { section: 1, coef: -60 },
      { section: 2, coef: -70 },
      { section: 3, coef: -70 },
      { section: 3, coef: 45, posZ: 120 }
    ]
    const ctsArray = document.querySelectorAll('.callToScroll')

    ctsArray.forEach((cts, i) => {
      this.setCallToScroll({
        domElement: cts,
        params: paramsArray[i],
      })
    })
  }

  setCallToScroll ({ domElement, threeInheritedObject = null, params }) {
    const position = new THREE.Vector3()
    position.y = this.sizes.perspectiveCameraHeightPercentage({
      coef: params.coef,
      section: params.section
    })

    if (params.posZ) {
      position.z = params.posZ
    }

    const cssObject = this.cssRenderer.createCssObject({
      position,
      domElement
    })

    const scale = .04
    cssObject.scale.set(scale, scale, scale)


    if (threeInheritedObject) {
      const cssThreeGroup = this.cssRenderer.setGroupFromThree(threeInheritedObject)
      cssThreeGroup.add(cssObject)
    } else {
      this.cssRenderer.cssScene.add(cssObject)
    }
  }

  initWorldScenes () {
    this.presentation = new Presentation()
    this.skills = new Skills()
    this.environment = new Environment()

    this.updatesList.push(...[
      () => this.environment.update(),
      () => this.home.update(),
      () => this.presentation.update(),
      () => this.skills.update(),
      () => this.ground.update()
    ])
  }

  setMainParticles () {
    // Geometry
    const particlesCount = 2000
    const positions = new Float32Array(particlesCount * 3)
    const sectionFloors = 4

    for (let i = 0; i < particlesCount; i++) {
      positions[i * 3 + 0] = (Math.random() - .5) * 80
      positions[i * 3 + 1] = this.experience.objectsDistance * .5 - Math.random() * this.experience.objectsDistance * sectionFloors
      positions[i * 3 + 2] = (Math.random() - .5) * 80
    }

    const particlesGeometry = new THREE.BufferGeometry()
    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    // Material
    const particlesMaterial = this.meshes.materials.particles

    // Points
    this.mainParticles = new THREE.Points(particlesGeometry, particlesMaterial)
    this.scene.add(this.mainParticles)
  }

  setContactParticles () {
    // Geometry
    const particlesCount = 500
    const positions = new Float32Array(particlesCount * 3)

    for (let i = 0; i < particlesCount; i++) {
      positions[i * 3 + 0] = (Math.random() - .5) * 80
      positions[i * 3 + 1] = (this.experience.objectsDistance * .5 - this.experience.objectsDistance * 3) - Math.random() * this.experience.objectsDistance
      positions[i * 3 + 2] = (Math.random() - .5) * 80
    }

    const particlesGeometry = new THREE.BufferGeometry()
    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    // Material
    const particlesMaterial = this.meshes.materials.particles

    // Points
    this.contactParticles = new THREE.Points(particlesGeometry, particlesMaterial)
    this.contactParticles.position.z = 100
    this.scene.add(this.contactParticles)
  }

  particlesUpdate () {
    this.contactParticles.rotation.y = this.mainParticles.rotation.y = this.time.elapsed / 30000
  }

  particlesManager () {
    this.meshes.addMaterial('particles', 'PointsMaterial', {
      color: 0xffffff,
      sizeAttenuation: true,
      size: .03
    })

    this.setMainParticles()
    this.setContactParticles()

    this.updatesList.push(() => this.particlesUpdate())
  }

  update () {
    this.updatesList.forEach(update => update())
  }
}
