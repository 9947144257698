export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path: [
            require('../../assets/textures/environmentMap/px.jpg'),
            require('../../assets/textures/environmentMap/nx.jpg'),
            require('../../assets/textures/environmentMap/py.jpg'),
            require('../../assets/textures/environmentMap/ny.jpg'),
            require('../../assets/textures/environmentMap/pz.jpg'),
            require('../../assets/textures/environmentMap/nz.jpg')
        ]
    },
    {
        name: 'marvinVisionsBold',
        type: 'font',
        path: require('../../assets/fonts/marvinVisionsBold.json')
    },
    {
        name: 'cubeWireframe',
        type: 'gltfModel',
        path: require('../../assets/models/cube.glb')
    },
    {
        name: 'pyramidWireframe',
        type: 'gltfModel',
        path: require('../../assets/models/pyramid.glb')
    },
    {
        name: 'skillsCircle',
        type: 'svg',
        path: require('../../assets/images/skillsCircle.svg')
    },
    {
        name: 'logoJS',
        type: 'texture',
        path: require('../../assets/images/logo-js.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoTS',
        type: 'texture',
        path: require('../../assets/images/logo-ts.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoBurp',
        type: 'texture',
        path: require('../../assets/images/burp-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoGithub',
        type: 'texture',
        path: require('../../assets/images/github-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoGraphQL',
        type: 'texture',
        path: require('../../assets/images/graphql-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoLighthouse',
        type: 'texture',
        path: require('../../assets/images/lighthouse-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoDocker',
        type: 'texture',
        path: require('../../assets/images/logo-docker.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoGsap',
        type: 'texture',
        path: require('../../assets/images/logo-gsap.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoThree',
        type: 'texture',
        path: require('../../assets/images/logo-three.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoNodeJS',
        type: 'texture',
        path: require('../../assets/images/node-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoOWASP',
        type: 'texture',
        path: require('../../assets/images/owasp-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoProject365',
        type: 'texture',
        path: require('../../assets/images/project365-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'logoStrapi',
        type: 'texture',
        path: require('../../assets/images/strapi-logo.jpg'),
        useNearestFilter: true
    },
    {
        name: 'mailBox',
        type: 'gltfModel',
        path: require('../../assets/models/mailBox.glb')
    },
    {
        name: 'epiconscienceWebsitePreview',
        type: 'video',
        mime: 'video/webm',
        path: require('../../assets/videos/epiconscience-website-preview.webm')
    },
    {
        name: 'logoLinkedIn',
        type: 'texture',
        path: require('../../assets/images/linkedin-logo.jpg')
    },
    {
        name: 'logoStackOverflow',
        type: 'texture',
        path: require('../../assets/images/stackoverflow-logo.jpg')
    },
    {
        name: 'cvIcon',
        type: 'texture',
        path: require('../../assets/images/cv-icon.jpg')
    }
]
