const baseColors = {
  black: 0x1E1E1E,
  grey: 0x5F5F5F,
  white: 0xF8F8F8,
  green: 0x20B2AA,
  yellow: 0xFFC400,
  orange: 0xE65F1D,
  pink: 0xE74769,
  purple: 0x715ef2
}

export default baseColors
