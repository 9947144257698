import * as THREE from 'three'
import Experience from './Experience.js'
import colors from './Utils/colors.js'

export default class Meshes {
  constructor () {
    this.experience = new Experience()

    this.materials = {
      phong: {},
      basic: {},
      standard: {}
    }
    this.geometries = {}
    this.instances = {}

    this.setBaseMaterials()
  }

  setBaseMaterials () {
    this.addMaterial('pink', 'phong', { color: colors.pink })
    this.addMaterial('yellow', 'phong', { color: colors.yellow })
    this.addMaterial('green', 'phong', { color: colors.green })

    this.addMaterial('black', 'standard', { color: colors.black })
    this.addMaterial('green', 'standard', { color: colors.green })
    this.addMaterial('purple', 'standard', { color: colors.purple })
    this.addMaterial('pink', 'standard', { color: colors.pink })
  }

  addMaterial (name = '', materialType = '', specs = {}) {
    switch (materialType) {
      case 'phong':
        return this.materials.phong[name] = new THREE.MeshPhongMaterial(specs)
      case 'basic':
        return this.materials.basic[name] = new THREE.MeshBasicMaterial(specs)
      case 'standard':
        return this.materials.standard[name] = new THREE.MeshStandardMaterial(specs)
      default:
        return this.materials[name] = new THREE[materialType](specs)
    }
  }
}
