import * as THREE from 'three'
import Experience from '../Experience.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import colors from '../Utils/colors.js'
import gsap from 'gsap'

export default class Home {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.meshes = this.experience.meshes

    this.cubePos = { x: -26, y: -32.8, z: 7 }
    this.pyramidPos = { x: 24, y: 28.8, z: -7 }

    this.polygonsGroup = new THREE.Group()
    this.scene.add(this.polygonsGroup)

    this.updatesList = []

    this.resources.on('ready', () => {
      this.setWireframeCube()
      this.setWireframePyramid()
      this.setHeroTitle()
    })
  }

  setWireframeCube () {
    this.cubeWireframe = this.resources.items.cubeWireframe.scene.children[0].children[0]

    this.cubeWireframe.material = this.meshes.materials.phong.pink
    this.cubeWireframe.scale.set(50, 50, 50)

    this.polygonsGroup.add(this.cubeWireframe)

    this.updatesList.push(() => this.updateWireframeCube())
  }

  updateWireframeCube () {
    this.cubeWireframe.rotation.x += .001
    this.cubeWireframe.rotation.y -= .001
    this.cubeWireframe.position.x = Math.sin(this.time.elapsed / 1000) + this.cubePos.x
    this.cubeWireframe.position.y = this.sizes.perspectiveCameraHeightPercentage({
      coef: (Math.cos(this.time.elapsed / 1500) * 5) - 30,
      section: 0
    })
  }

  setWireframePyramid () {
    this.pyramidWireframe = this.resources.items.pyramidWireframe.scene.children[0].children[0]

    this.pyramidWireframe.material = this.meshes.materials.phong.yellow
    this.pyramidWireframe.scale.set(40, 40, 40)

    this.polygonsGroup.add(this.pyramidWireframe)

    this.updatesList.push(() => this.updateWireframePyramid())
  }

  updateWireframePyramid () {
    this.pyramidWireframe.rotation.x -= .001
    this.pyramidWireframe.rotation.y += .001
    this.pyramidWireframe.position.x = Math.sin(this.time.elapsed / 1500) + this.pyramidPos.x
    this.pyramidWireframe.position.y = this.sizes.perspectiveCameraHeightPercentage({
      coef: (Math.sin(this.time.elapsed / 1000) * 5) + 30,
      section: 0
    })
  }

  setHeroTitle () {
    const baseFont = {
      font: this.resources.items.marvinVisionsBold,
      height: 0
    }

    const material = this.meshes.materials.phong.green

    const pdarrieu = new TextGeometry('Pierre Darrieutort', Object.assign(baseFont, { size: 3.155 }))
    pdarrieu.center()

    const creative = new TextGeometry('Creative', Object.assign(baseFont, { size: 7.3 }))
    creative.center()

    const developer = new TextGeometry('Developer', Object.assign(baseFont, { size: 5.7 }))
    developer.center()


    const mesh1 = new THREE.Mesh(pdarrieu, material)
    mesh1.position.y = 6.8

    const mesh2 = new THREE.Mesh(creative, material)
    mesh2.position.y = .25

    const mesh3 = new THREE.Mesh(developer, material)
    mesh3.position.y = -6.65

    this.scene.add(mesh1, mesh2, mesh3)
  }

  update () {
    if (!this.camera.controls.enabled && this.camera.instance instanceof THREE.PerspectiveCamera && this.camera.currentSection < 2) {
      gsap.set(this.polygonsGroup.position, { y: this.camera.instance.position.y })
      gsap.to(this.polygonsGroup.rotation, 2, { y: this.camera.currentSection ? Math.PI : 0 })
    }

    this.updatesList.forEach(update => update())
  }
}
