import * as THREE from 'three'
import Experience from '../../Experience.js'
import * as CANNON from 'cannon-es'
import colors from '../../Utils/colors.js'

export default class Contact {
  constructor () {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.cannonWorld = this.experience.cannonWorld
    this.ground = this.experience.world.ground
    this.meshes = this.experience.meshes

    this.sectionHeight = 3
    this.innerContactFloor = this.sizes.perspectiveCameraHeightPercentage({ coef: -100 })

    this.socialBoxesArray = []

    this.initGroup()
    this.setSocialBoxes()
    this.setMailBox()
  }

  initGroup () {
    this.contactGroup = new THREE.Group()
    this.contactGroup.position.y = this.sizes.perspectiveCameraHeightPercentage({
      section: this.sectionHeight
    })
    this.contactGroup.position.z = 100

    this.scene.add(this.contactGroup)

    const plane = this.ground.getGroundCircle()
    plane.position.y = this.innerContactFloor
    this.contactGroup.add(plane)
  }

  setSocialBoxes () {
    const socialList = [
      {
        name: 'github',
        url: 'https://github.com/pierredarrieutort',
        texture: this.resources.items.logoGithub,
        offsetX: 1,
        rotationY: Math.PI / 12,
        color: colors.grey
      },
      {
        name: 'linkedin',
        url: 'https://www.linkedin.com/in/pierre-darrieutort/',
        texture: this.resources.items.logoLinkedIn,
        offsetX: 2,
        rotationY: Math.PI / 8,
        color: colors.purple
      },
      {
        name: 'stackoverflow',
        url: 'https://stackoverflow.com/users/12084219/pedrozorus',
        texture: this.resources.items.logoStackOverflow,
        offsetX: -1,
        rotationY: Math.PI / 6,
        color: colors.orange
      },
      {
        name: 'cv',
        url: require('../../../../assets/files/CV-PIERRE_DARRIEUTORT.pdf'),
        texture: this.resources.items.cvIcon,
        offsetX: 2,
        rotationY: Math.PI / 4,
        color: colors.green
      }
    ]

    this.socialBoxes = new THREE.Group()
    // this.socialBoxes.position.x = this.sizes.perspectiveCameraWidthPercentage(-49)
    this.socialBoxes.position.x = -25
    this.socialBoxes.position.z = -15
    this.contactGroup.add(this.socialBoxes)

    socialList.forEach((el, i) => {
      this.meshes.addMaterial(el.name, 'phong', { shininess: 100, map: el.texture })
      this.setSocialBox(el, i)
    })
    this.experience.mouse.intersectables.socialBoxes = this.socialBoxesArray
  }

  setSocialBox (el, i) {
    const size = 10
    const boxGeometry = new THREE.BoxGeometry(size, size, size)
    const boxMaterial = this.meshes.materials.phong[el.name]
    const box = new THREE.Mesh(boxGeometry, boxMaterial)

    const boxAspectRatio = box.geometry.parameters.width / box.geometry.parameters.height
    const textureAspectRatio = el.texture.image.width / el.texture.image.height

    box.material.wrapT = THREE.ClampToEdgeWrapping
    box.material.map.repeat.y = textureAspectRatio / boxAspectRatio
    box.material.map.offset.y = .5 * (1 - box.material.map.repeat.y)
    box.material.needsUpdate = true

    box.position.y = (this.innerContactFloor + (size / 2)) + (size * i)
    box.position.x = el.offsetX

    box.rotation.y = el.rotationY

    box.name = el.name
    box.url = el.url
    box.color = new THREE.Color(el.color)

    this.socialBoxesArray.push(box)
    this.socialBoxes.add(box)
  }

  setMailBox () {
    this.mailBox = this.resources.items.mailBox.scene.children[0]
    this.mailBox.position.y = this.innerContactFloor
    // this.mailBox.position.x = this.sizes.perspectiveCameraWidthPercentage(55)
    this.mailBox.position.x = 25
    this.mailBox.position.z = -15

    this.mailBox.rotation.y = - Math.PI / 4

    this.contactGroup.add(this.mailBox)

    this.experience.mouse.intersectables.mailBox = this.mailBox
  }
}
